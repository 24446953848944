<template>
    <div>
        <div class="home-new-box" :class="{'mobile-style': isMobile}">
            <div class="max-w hn-header flex-box space-between">
                <img src="../assets/img/logo.png" alt="">
                <div v-if="!isMobile" class="hh-linktab mg-l80 flex-1 flex-box">
                    <div v-for="(item, index) in footerArr" :key="index" class="flex-box mg-r32 cursor-pointer hh-l-hc" :class="`hh-l-hc-${index} hidden-${index === 3 ? 1 : 0}`">
                        <span class="hh-l-t">{{ item.title }}</span>
                        <img class="hh-l-d mg-l8" src="../assets/img/down.png" alt="">
                        <div class="hh-l-hover" :class="`hh-l-hover-${index}`">
                            <a v-for="(it, key) in item.links" :key="key" :href="it.link" target="_blank" class="color-6B7280 text-decoration">{{ it.name }}</a>
                        </div>
                    </div>
                </div>
                <div v-if="!isMobile" class="social-main flex-box space-between mg-r36">
                    <a v-for="(item, index) in socialArr" :key="index" :href="item.link" target="_blank">
                        <img :src="item.img" alt="">
                    </a>
                </div>
                <div v-if="isMobile" @click="isDrawer = true">
                    <img src="../assets/img/Button.png" class="hh-button-m" alt="">
                </div>

                <el-drawer
                    direction="ttb"
                    :size="460"
                    :visible.sync="isDrawer">
                    <div class="hh-f h-footer-box">
                        <div class="hfo-l flex-box align-start">
                            <div v-for="(item, index) in footerArr" :key="index" class="flex-1 pd-l80">
                                <p class="hfo-title" v-if="index !== 3">{{ item.title }}</p>
                                <ul class="hfo-name" v-if="index !== 3">
                                    <li v-for="(it, key) in item.links" :key="key" class="mg-b16">
                                        <a :href="it.link" target="_blank" class="color-6B7280 text-decoration">{{ it.name }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="text-center">
                            <div class="flex-box space-center hfo-media">
                                <a v-for="(item, index) in socialArr" :key="index" :href="item.link" target="_blank">
                                    <img :src="item.img" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                </el-drawer>
            </div>
            <div class="max-w h-introduce-box flex-box space-between">
                <div class="hi-main flex-1">
                    <p class="hi-m-font-big">A Fraud-proof, </p>
                    <p class="hi-m-font-big">User-centric Layer-1 for Mass Adoption</p>
                    <p class="ts-20 color-6B7280">The Infrastructure for Builders, protecting investors and users with fraud-proof mechanisms such as Wallet Freezing/Unfreezing, pincode-based security valve, while allowing massive access via Keyless Wallet, supporting developers with one-stop toolbox for high performances.</p>
                    <!-- <div class="hi-build">Start Building</div> -->
                </div>
                <div v-if="!isMobile" class="hi-img">
                    <img src="../assets/img/maincontent.png" alt="">
                </div>
            </div>
            <div v-if="isMobile" class="hi-img">
                <img src="../assets/img/maincontent_m.png" alt="">
            </div>
            <div class="max-w hi-friends mg-t32">
                <div class="hi-f-box flex-box space-between">
                    <div class="hi-f-item flex-1 flex-box space-center" v-for="(item, index) in friendArr" :key="index">
                        <img :src="item.img" :style="`height: ${item.height}px;`" alt="">
                    </div>
                </div>
            </div>
            <div class="h-features-bg">
                <div class="max-w hf-main">
                    <p class="hf-title text-center">Features</p>
                    <div class="hf-item flex-box space-between" v-for="(item, index) in featureArr" :key="index" :class="{'row-reverse': index % 2}">
                        <div class="hf-i-cont">
                            <p class="hf-ic1 mg-tb0">{{ item.title }}</p>
                            <p class="hf-ic2 color-6B7280" v-html="item.cont"></p>
                        </div>
                        <div class="hf-i-img">
                            <img :src="item.img" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="h-roadmap-bg">
                <div class="dashed-line"></div>
                <div class="max-w">
                    <p class="hr-title text-center">Roadmap</p>
                    <div class="flex-box hr-box">
                        <div class="hr-item flex-1" v-for="(item, index) in roadmapArr" :key="index">
                            <p class="hr-i-data" :class="{'color-00ce77': item.state}">{{item.date}}</p>
                            <div v-if="!item.state" class="hr-i-state"></div>
                            <div v-else class="hr-is-icon flex-box space-center">
                                <img src="../assets/img/yes.png" alt="">
                            </div>
                            <div class="hr-i-list">
                                <ul>
                                    <li v-for="(it, key) in item.target" :key="key" :class="{'color-00ce77': item.state}">{{ it }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="h-tagline-bg">
                <div class="max-w">
                    <p class="ht-title text-center">Tagline Introductions</p>
                    <div class="ht-box flex-box align-stretch">
                        <div class="ht-in-box flex-1">
                            <div><img src="../assets/img/icon-park_file-protection-one.png" alt=""></div>
                            <p class="ht-ib-title">Protecting all Investors & Users</p>
                            <p class="ht-ib-cont color-6B7280">With a cutting-edge and unique mechanism that is fraud-proof and highly responsive with Wallet Freezing/Unfreezing during emergent incidents, for example, in case of private key compromised, a PIN code will be required to unfreeze the wallets, making sure that the users will have full access to the accounts during unexpected and unwanted cases. Transaction validations are required in every trade that you make.</p>
                        </div>
                        <div class="ht-in-box flex-1 mg-l40">
                            <div><img src="../assets/img/clarity_wallet-line.png" alt=""></div>
                            <p class="ht-ib-title">Lowering the Access for Mass Adoption</p>
                            <p class="ht-ib-cont color-6B7280">The Keyless Wallet created by the Soruba team is something with higher security while lower limitations to acquire and grow users than other forms such as AA wallets. With highest performance while lowest fees among L1s and L2s, Soruba is outperforming the similar infrastructure projects for the most profound support for developers.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="max-w h-footer-box">
                <div class="hfo-l flex-box align-start">
                    <div v-for="(item, index) in footerArr" :key="index" class="flex-1 pd-l80">
                        <p class="hfo-title">{{ item.title }}</p>
                        <ul class="hfo-name">
                            <li v-for="(it, key) in item.links" :key="key" class="mg-b16">
                                <a :href="it.link" target="_blank" class="color-6B7280 text-decoration">{{ it.name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="text-center">
                    <img src="../assets/img/logo.png" alt="" class="hfo-logo">
                    <p class="ts-16 color-6B7280 mg-tb24">The Ultimate Layer-1 for all dApps.</p>
                    <div class="flex-box space-center hfo-media">
                        <a v-for="(item, index) in socialArr" :key="index" :href="item.link" target="_blank">
                            <img :src="item.img" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            isDrawer: false,
            socialArr: [
                {
                    link: 'https://twitter.com/SorubaL1',
                    img: require('../assets/img/twitter.png'),
                    imgFooter: require('../assets/img/twitter_footer.png'),
                },
                {
                    link: 'https://soruba.medium.com/',
                    img: require('../assets/img/medium.png'),
                    imgFooter: require('../assets/img/medium_footer.png'),
                },
                {
                    link: 'https://t.me/sorubachain',
                    img: require('../assets/img/telegram.png'),
                    imgFooter: require('../assets/img/telegram_footer.png'),
                },
                {
                    link: 'https://discord.gg/S3YFs3He',
                    img: require('../assets/img/discord.png'),
                    imgFooter: require('../assets/img/discord_footer.png'),
                },
                {
                    link: 'https://soruba-the-layer1-for-all.gitbook.io/soruba-litepaper-v1.0/',
                    img: require('../assets/img/gitbook.png'),
                    imgFooter: require('../assets/img/gitbook_footer.png'),
                }
            ],
            friendArr: [
                {
                    img: require('../assets/img/Google.png'),
                    height: 40
                },
                {
                    img: require('../assets/img/Microsoft.png'),
                    height: 32
                },
                {
                    img: require('../assets/img/Lenove.png'),
                    height: 38
                },
                {
                    img: require('../assets/img/motorola.png'),
                    height: 38
                },
                {
                    img: require('../assets/img/Pub.png'),
                    height: 52
                }
            ],
            roadmapArr: [
                {
                    date: 'Q1 2024 Nagoya',
                    state: true,
                    target: [
                        'Testnet goes live (completed)',
                        'Support smart contract',
                        'Support Metamask wallet',
                        'Basic rule-based engines implemented'
                    ]
                },
                {
                    date: 'Q2 2024 Kyoto',
                    state: false,
                    target: [
                        'Testnet Token faucet',
                        'Testnet-II goes live',
                        'Full rule-based engines implemented',
                        'Soruba Web Wallet beta live'
                    ]
                },
                {
                    date: 'Q3 2024 Osaka',
                    state: false,
                    target: [
                        'Soruba Mainnet live',
                        'Soruba Web Wallet Alpha Launch',
                        'Android Wallet',
                        'Bridging 50+ L1s & L2s',
                        'Keyless Wallet Beta Launch'
                    ]
                },
                {
                    date: 'Q4 2024 Tokyo',
                    state: false,
                    target: [
                        'Rule-based engines Phase-II',
                        'Soruba Web Wa llet Beta Launch',
                        'iOS Wallet',
                        'Bridging V2 Launch',
                        'Keyless Wallet Full Launch'
                    ]
                },
            ],
            featureArr: [
                {
                    img: require('../assets/img/feature.png'),
                    title: `Highest Level of User Protection`,
                    cont: 'Enhanced security and control via decentralized wallet permissions, transaction validating and <b>wallet freezing/unfreezing</b> mechanism to protect users\' assets from frauds, attacks and scams. <b>We at Soruba protect your assets</b>, with the most cutting-edge and comprehensive mechanism. '
                },
                {
                    img: require('../assets/img/feature2.png'),
                    title: 'Unlimiting the Massive Adoption',
                    cont: 'With high-performance parameters like TPS, scalability, interoperability etc, utilizing unique features such as <b>Keyless Wallet</b>, Soruba enables the most unlimited access for all users from both Web2 and Web3. Pincode can be used to unlock your freezed wallet to ensure a seamless and AA-like user experience.'
                },
                {
                    img: require('../assets/img/feature3.png'),
                    title: 'Higher Performance across L1s & L2s',
                    cont: `The native SRB token allows platform fees of approximately $0.0000001 through real-time calibration, enabling <b>affordable micropayments</b>. Low transaction fees, lightning-fast confirmation time, rule based engine etc enable Soruba to stand out in terms of performances.`
                },
                {
                    img: require('../assets/img/feature4.png'),
                    title: 'Validator Network for the Highest Scalability',
                    cont: 'Optimized validator node requirements also ensure high scalability exceeding 2000 TPS. <b>Low latency</b> results in blocks being produced every 3 seconds on average. A <b>highly-incentivizing Validator</b> Program will be introduced to welcome all nodes to cobuild Soruba Ecosystem after mainnet launch.'
                }
            ],
            footerArr: [
                {
                    title: 'Eco dApps',
                    links: [
                        {
                            name: 'Harutrade',
                            link: 'https://harutrade.finance/'
                        },
                        {
                            name: 'NFT Smasher',
                            link: 'https://nftsmasher.com/'
                        },
                        {
                            name: 'smartAIaudit',
                            link: 'https://smartaiaudit.xyz/'
                        }   
                    ]
                },
                {
                    title: 'Build',
                    links: [
                        {
                            name: 'Documentation',
                            link: 'https://soruba-the-layer1-for-all.gitbook.io/soruba-litepaper-v1.0/'
                        },
                        // {
                        //     name: 'API Docs',
                        //     link: ''
                        // },
                    ]
                },
                {
                    title: 'Links',
                    links: [
                        {
                            name: 'SorubaScan',
                            link: 'http://sorubascan.io/'
                        },
                    ]
                },
                {
                    title: 'Community',
                    links: [
                        {
                            name: 'Twitter',
                            link: 'https://twitter.com/SorubaL1'
                        },
                        {
                            name: 'Medium',
                            link: 'https://soruba.medium.com/'
                        },
                        {
                            name: 'Discord',
                            link: 'https://discord.gg/S3YFs3He'
                        },
                        {
                            name: 'Telegram',
                            link: 'https://t.me/sorubachain'
                        },
                    ]
                },
            ]
        }
    },
    computed: {
        isMobile () {
            return /mobile/i.test(navigator.userAgent) || screen.width < 1100
        }
    },
    mounted () {
    },
    methods: {
    },
    beforeDestroy () {
    }
}
</script>
<style lang="scss" scoped>
@font-face {
    font-family: AzeretMono;
    src: url("../assets/AzeretMono.ttf");
}
.color-6B7280{
    color: rgba(107, 114, 128, 1);
}
.color-00ce77{
    color: rgba(0, 206, 119, 1) !important;
}
.home-new-box{
    min-height: calc(100vh);
    position: relative;
    background-color: #ffffff;
    .hn-header{
        height: 80px;
        padding: 0 36px;
        background-color: #ffffff;
        img{
            height: 36px;
        }
        .hh-button-m{
            width: 24px;
            height: 24px;
            display: inherit;
        }
        .hh-f{
            padding: 0 16px;
        }
        .hh-linktab{
            .hh-l-hc-0{
                &:hover{
                    .hh-l-hover-0{
                        display: inline-block;
                    }
                }
            }
            .hh-l-hc-1{
                &:hover{
                    .hh-l-hover-1{
                        display: inline-block;
                    }
                }
            }
            .hh-l-hc-2{
                &:hover{
                    .hh-l-hover-2{
                        display: inline-block;
                    }
                }
            }
            .hh-l-hc-3{
                &:hover{
                    .hh-l-hover-3{
                        display: inline-block;
                    }
                }
            }
            .hh-l-hc{
                position: relative;
                &.hidden-1{
                    display: none !important;
                }
            }
            .hh-l-t{
                font-size: 16px;
                font-weight: 600;
            }
            .hh-l-d{
                width: 20px;
                height: 20px;
            }
            .hh-l-hover{
                display: none;
                width: 160px;
                position: absolute;
                top: 22px;
                left: -20px;
                box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
                padding: 12px;
                color: rgba(95, 109, 126, 1);
                font-size: 14px;
                a{
                    display: block;
                    padding: 6px 0;
                }
            }
        }
        .social-main{
            width: 200px;
            img{
                width: 32px;
                height: 32px;
                display: block;
            }
        }
    }
    .h-introduce-box{
        padding: 0 0 0 36px;
        .hi-main{
            max-width: 800px;
            .hi-m-font-big{
                font-size: 60px;
                line-height: 1;
                font-weight: 900;
                margin: 0;
            }
            .hi-build{
                display: inline-block;
                background: rgba(0, 206, 119, 1);
                font-size: 16px;
                border-radius: 8px;
                color: #ffffff;
                padding: 12px 22px;
                cursor: pointer;
                margin-top: 24px;
            }
        }
        .hi-img{
            width: 500px;
            img{
                width: 100%;
            }
        }
    }
    .hi-friends{
        padding: 0 36px;
        .hi-f-item{
            img{
            }
        }
    }
    .h-features-bg{
        background-color: rgba(249, 250, 251, 1);
        padding: 60px 36px;
        margin-top: 50px;
        .hf-title{
            font-size: 36px;
            font-weight: 800;
            margin: 0 0 60px;
        }
        .hf-item{
            margin-bottom: 60px;
            &.row-reverse{
                flex-direction: row-reverse;
            }
            .hf-i-cont{
                max-width: 650px;
                .hf-ic1{
                    font-size: 36px;
                    font-weight: 800;
                }
                .hf-ic2{
                    font-size: 20px;
                    .span-w{
                        font-weight: 700;
                    }
                }
            }
            .hf-i-img{
                height: 455px;
                img{
                    height: 100%;
                }
            }
        }
    }
    .h-roadmap-bg{
        background-color: rgba(0, 0, 0, 1);
        padding: 60px 36px;
        position: relative;
        .dashed-line{
            width: 100%;
            border-top: 1px dashed rgba(87, 87, 87, 1);
            position: absolute;
            top: 266px;
            left: 0;
            z-index: 0;
            box-sizing: border-box;
        }
        .hr-title{
            color: #ffffff;
            font-size: 36px;
            font-weight: 800;
            margin: 0 0 60px;
        }
        .hr-item{
            padding-right: 32px;
            .hr-i-data{
                color: #ffffff;
                font-size: 22px;
                font-weight: 600;
            }
            .hr-i-state{
                border: 1px dashed rgba(171, 171, 171, 1);
                width: 30px;
                height: 30px;
                border-radius: 50%;
                box-sizing: border-box;
                z-index: 1;
                position: relative;
                background-color: rgba(0, 0, 0, 1);
            }
            .hr-is-icon{
                background-color: rgba(0, 206, 119, 1);
                border-radius: 50%;
                width: 30px;
                height: 30px;
                box-sizing: border-box;
                z-index: 1;
                position: relative;
                img{
                    width: 16px;
                }
            }
            .hr-i-list{
                margin-top: 32px;
                box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.05);
                // box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
                ul{
                    height: 260px;
                    color: rgba(95, 109, 126, 1);
                    background: #ffffff;
                    padding: 40px 50px;
                    border-radius: 8px;
                    box-sizing: border-box;
                    li{
                        list-style: disc;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    .h-tagline-bg{
        background-color: rgba(249, 249, 249, 1);
        padding: 60px 36px;
        .ht-title{
            font-size: 36px;
            font-weight: 800;
            margin: 0 0 60px;
        }
        .ht-in-box{
            box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.05);
            background-color: #ffffff;
            padding: 32px;
            border-radius: 8px;
            border: 1px solid rgba(229, 231, 235, 1);
            box-sizing: border-box;
            img{
                width: 48px;
            }
            .ht-ib-title{
                font-size: 24px;
                font-weight: 600;
            }
            .ht-ib-cont{
                font-size: 18px;
            }
        }
    }
    .h-footer-box{
        padding: 60px 36px;
        .hfo-title{
            color: rgba(17, 25, 40, 1);
            font-size: 14px;
            font-weight: 600;
        }
        .hfo-name{
            font-size: 16px;
            color: rgba(107, 114, 128, 1);
        }
        .hfo-logo{
            height: 36px;
        }
        .hfo-media{
            a{
                margin: 0 12px;
                img{
                    height: 32px;
                }
            }
        }
    }
    &.mobile-style{
        .hn-header{
            height: 68px;
            padding: 0 16px;
            border-bottom: 1px solid rgba(234, 235, 240, 1);
        }
        .h-introduce-box{
            display: block !important;
            padding: 0 16px;
            text-align: center;
            margin-top: 24px;
            .hi-m-font-big{
                font-size: 36px;
            }
            .hi-build{
                margin-top: 0;
            }
        }
        .hi-img{
            img{
                width: 100%;
                margin-top: -65px;
            }
        }
        .hi-friends{
            padding: 0 16px;
            .hi-f-box{
                display: grid !important;
                grid-template-columns: 1fr 1fr;
                grid-row-gap: 40px;
                grid-column-gap: 20px;
            }
        }
        .h-features-bg{
            padding: 30px 16px;
            margin-top: 20px;
            .hf-title{
                margin-bottom: 30px;
            }
            .hf-item{
                display: block !important;
                .hf-ic1{
                    font-size: 24px;
                }
                .hf-i-img{
                    width: 100%;
                    height: unset;
                    img{
                        width: 100%;
                    }
                }
            }
        }
        .h-roadmap-bg{
            padding: 30px 36px;
            .dashed-line{
                display: none;
            }
            .hr-title{
                margin-bottom: 30px;
            }
            .hr-box{
                display: block !important;
                .hr-item{
                    padding-right: 0;
                }
                .hr-i-list{
                    ul{
                        height: unset !important;
                    }
                }
            }
        }
        .h-tagline-bg{
            padding: 30px 16px;
            .ht-title{
                margin-bottom: 0;
            }
            .ht-box{
                display: block !important;
                .ht-in-box{
                    margin-left: 0 !important;
                    margin-top: 24px;
                    padding: 24px;
                    .ht-ib-title{
                        margin: 16px 0;
                    }
                }
            }
        }
        .h-footer-box{
            padding: 30px 16px;
            .hfo-l{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-row-gap: 40px;
                grid-column-gap: 40px;
                margin-bottom: 20px;
                .pd-l80{
                    padding-left: 0 !important;
                }
            }
            .hfo-media{
                a{
                    margin: 0 6px;
                }
            }
        }
    }
}
</style>
